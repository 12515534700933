import HttpRequestService from "./HttpRequestService";

const AcademicService = {};

const cntrlName = "academic-session";

//API for get all data
AcademicService.getList = function () {
  return HttpRequestService.get(cntrlName);
};

//Api for get data by id
AcademicService.getById = function (id) {
  return HttpRequestService.get(cntrlName + "/" + id);
};


export default AcademicService;