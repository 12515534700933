const url = {
    API_ENDPOINT_URL: 'https://stusphere-stg-api.azurewebsites.net/api/',
    RAZORPAY_API_ENDPOINT_URL: 'https://stusphere.vercel.app/api/razorpay',
  };
  
  const getEnv = () => {
    return url
  }
  
  export const env = getEnv()
  