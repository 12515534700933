export const ACTION = {
  ADD: "ADD",
  EDIT: "EDIT",
  VIEW: "VIEW",
  DELETE: "DELETE",
  LIST: "LIST",
};

export const COURSETYPE = {
  CORE: 1,
  OPTIONAL: 2,
};

export const GRADEGROUP = {
  ElementrySchool: 1,
  MiddleSchool: 2,
  HighSchool: 3,
};

