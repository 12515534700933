import HttpRequestService from "./HttpRequestService";

const GradeService = {};

const cntrlName = "grade";
const categories = "categories";

//API for get all data
GradeService.getList = function () {
  return HttpRequestService.get(cntrlName);
};

//API for get all course
GradeService.getCourseList = function () {
  return HttpRequestService.get(`${cntrlName}/course`);
};


//API for get all categories data
GradeService.getAllCategories = function () {
  return HttpRequestService.get(`${cntrlName}/${categories}`);
};

export default GradeService;
