import React, { useState } from "react";
import { Modal } from "antd";

const Apply = (props) => {
  const [show, setShow] = useState(false);

  return (
    <div className="title-box">
      <div className="title p-3">
        <h5>Welcome to Our School's Admission Page</h5>
      </div>
      <p className="justified">
        Are you ready to embark on an incredible educational journey with us? We
        are thrilled to invite you to apply and become part of our vibrant and
        dynamic community. Whether you're a passionate learner, a driven
        professional, or someone seeking personal growth, Our School is the
        perfect place for you to achieve your goals and unlock your full
        potential.
      </p>

      <p className="justified">
        To start your application process, we kindly ask you to gather all the
        necessary documents and information beforehand. This will ensure a
        smooth and efficient application experience. Please have the following
        items ready:
        <ul className="wp-block-categories-list wp-block-categories ">
          <li className="cat-item">
            <p>
              <strong>1. Personal Identification - </strong> Prepare a valid
              government-issued identification document, such as a passport or
              driver's license. This will help us verify your identity and
              maintain the security of our admissions process.
            </p>
          </li>
          <li className="cat-item">
            <p>
              <strong>2. Academic Transcripts - </strong> Collect your academic
              transcripts from your previous educational institutions. These
              records will provide us with valuable insights into your academic
              background and achievements.
            </p>
          </li>
          <li className="cat-item">
            <p>
              <strong>3. Statement of Purpose -</strong> We encourage you to
              share your aspirations, motivations, and goals in a well-crafted
              statement of purpose. This document allows us to understand your
              unique story, interests, and how Our School can contribute to your
              growth.
            </p>
          </li>
          <li className="cat-item">
            <p>
              <strong>4. Letters of Recommendation -</strong> Request letters of
              recommendation from individuals who can attest to your character,
              academic abilities, or professional experience. These letters
              provide additional insights into your skills and potential.
            </p>
          </li>
          <li className="cat-item">
            <p>
              <strong>5. Test Scores -</strong> If applicable, please submit any
              standardized test scores required for your desired program or
              field of study. These scores can include SAT, ACT, GRE, GMAT, or
              other relevant assessments
            </p>
          </li>
        </ul>
      </p>

      <div className="form-group ">
        <p className="justified">
          <button
            type="button"
            className="registration-btn btn clearfix btn-default"
            data-bs-dismiss="modal"
            onClick={() => setShow(true)}
          >
            <span className="btn-wrap">
              <span className="text-one">Apply Now</span>
              <span className="text-two">Apply Now</span>
            </span>
          </button>
          <span className="pad-lf-10">
            Apply now! We can't wait to have you on board. How to apply? Let us
            guide you through the process.
          </span>
        </p>
      </div>

      <Modal
        title="GRADE SELECTION"
        className="modalStyle"
        open={show}
        onCancel={() => setShow(false)}
        footer={null}
        maskClosable={false}
      >
        <p>
          If you are a parent looking to enroll your student in online courses
          or K-12 school, enroll now in flexible class options for grades K-12.
        </p>

        <div className="text-center">
          {props.courseList
            ?.filter(
              (option, index, self) =>
                index ===
                self.findIndex((o) => o.categoryId === option.categoryId)
            )
            .map((option) => (
              <button
                key={option.categoryId}
                type="button"
                className="btn clearfix login-btn"
                data-bs-dismiss="modal"
                onClick={() => props.setGradeGroup(option.categoryId)}
              >
                <span className="btn-wrap">
                  <span className="text-one">{option.categoryTitle}</span>
                  <span className="text-two">{option.categoryTitle}</span>
                </span>
              </button>
            ))}
        </div>

        <p>
          If you are a perspective student, explore flexible and full-time
          online/onsite education options.
        </p>
        <p>
          If you are an educator ready to enroll your students, please contact
          us.
        </p>
      </Modal>
    </div>
  );
};

export default Apply;
