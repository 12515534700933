import React, { useState } from "react";
import { Checkbox, Modal, message } from "antd";
import infoData from "../../assets/data/high-school-course/english-1.data.json";
import SectionTitle from "../../components/ConsultancyElements/SectionTitle";
import { COURSETYPE, GRADEGROUP } from "../../constants/GlobalConstant";

const CourseSelection = (props) => {
  const { groupId, courseList } = props;
  const [show, setShow] = useState(false);

  const viewCourseDetails = async (item) => {
    let selectedGrade = "";
    const fileName = item.fileName;

    if (groupId === GRADEGROUP.HighSchool) {
      selectedGrade = "high-school";
    }

    if (groupId === GRADEGROUP.MiddleSchool) {
      selectedGrade = "middle-school";
    }

    try {
      const response = await fetch(
        `/course-file/${selectedGrade}/${fileName}.data.json`
      );

      if (response) {
        const fileData = await response.json();
        if (fileData) {
          debugger;
        }
      }
      setShow(true);
    } catch (e) { 
      message.error("Course information doesn't exists");
    }
  };

  const handleCourseSelection = (event, grade, selectedCourse) => {
    const { checked } = event.target;

    const filteredList = courseList.filter((grade) =>
      grade.course.some((course) => course.checked)
    );

    if (
      filteredList.length &&
      filteredList.find((item) => item.id !== grade.id)
    ) {
      message.warning("You can't select more than one grade course");
      return false;
    }

    const updatedGrades = courseList.map((g) => {
      if (g.id === grade.id) {
        return {
          ...g,
          course: g.course.map((course) =>
            course.id === selectedCourse.id ? { ...course, checked } : course
          ),
        };
      }
      return g;
    });

    props.setCourse(updatedGrades);
  };

  return (
    <div className="title-box">
      <div className="title p-3">
        <h5>Course Selection</h5>
      </div>

      <div className="form-group text-center">
        <h6 className="justified">Please select your grade or course below.</h6>
        <br />
        <p className="justified">
          Please click on each grade level to view and select courses for your
          student. Each course selection will default to the full course, but
          you have the option to change that selection to only one segment if
          you prefer (see above for more information on segments).
        </p>
        {groupId === GRADEGROUP.ElementrySchool ? (
          <h6 className="justified red-color">Cost Per Segment - $ 75</h6>
        ) : null}
        <br />
        <div className="title">
          <div className="row">
            {courseList.map((grade) => (
              <div
                className="news-block col-lg-4 col-md-4 col-sm-12"
                key={grade.id}
              >
                <div className="inner-box">
                  <div className="lower-content course-list-height">
                    <div className="author-box">
                      <div className="title">{grade.title}</div>
                    </div>

                    <div className="lower-box justified text-capitalize overflow-scroll-course">
                      <strong>Core Courses</strong>
                      <hr />
                      <ul>
                        {grade.course
                          .filter((course) => course.typeId === COURSETYPE.CORE)
                          .map((course) => (
                            <li key={course.id}>
                              <Checkbox
                                checked={course.checked}
                                onChange={(event) =>
                                  handleCourseSelection(event, grade, course)
                                }
                              />
                              <span
                                className="school-course-span"
                                onClick={() => viewCourseDetails(course)}
                              >
                                {course.title}
                              </span>
                            </li>
                          ))}
                      </ul>
                      <strong>Optional Courses</strong>
                      <hr />
                      <ul>
                        {grade.course
                          .filter((course) => course.typeId === COURSETYPE.OPTIONAL)
                          .map((course) => (
                            <li key={course.id}>
                              <Checkbox
                                checked={course.checked}
                                onChange={(event) =>
                                  handleCourseSelection(event, grade, course)
                                }
                              />
                              <span
                                className="school-course-span"
                                onClick={() => viewCourseDetails(course)}
                              >
                                {course.title}
                              </span>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal
        title="COURSE DETAIL"
        className="modalStyle"
        open={show}
        onCancel={() => setShow(false)}
        footer={null}
        maskClosable={false}
        width={800}
      >
        <div className="widget widget_block widget_custom course-info-box">
          <SectionTitle title={infoData.title} />

          <div className="comments-area">
            <ul className="comment-list">
              <li className="comment even thread-even depth-1">
                <div className="commenter-block">
                  <div className="comment-content">
                    <div className="comment-author-name">
                      Prerequisites :
                      <span className="comment-date">
                        {infoData.prerequisites}
                      </span>
                    </div>
                    <div className="comment-author-name">
                      Credits :
                      <span className="comment-date">{infoData.credits}</span>
                    </div>
                    <div className="comment-author-name">
                      Estimated Completion Time :
                      <span className="comment-date">
                        {infoData.estimatedCompletionTime}
                      </span>
                    </div>
                    <div className="comment-author-name">
                      Earliest Start Date :
                      <span className="comment-date">
                        {infoData.earliestStartDate}
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <h2 className="widget-title">{infoData.description.title}</h2>

          <div className="sidebar_author">
            <p className="intro">{infoData.description.content}</p>
          </div>

          <h2 className="widget-title">{infoData.topics.title}</h2>

          {infoData.topics.segment.map((segment) => (
            <div className="blog-details pad-0" key={segment.title}>
              <div className="blog-details-inner">
                <h6>{segment.title}</h6>
                <ul className="point-order">
                  {segment.content.map((content) => (
                    <li key={content}>{content}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}

          <h2 className="widget-title">{infoData.gradingPolicy.title}</h2>

          <div className="sidebar_author">
            <p className="intro">{infoData.gradingPolicy.content}</p>
          </div>

          <h2 className="widget-title">{infoData.communications.title}</h2>

          <div className="sidebar_author">
            <p className="intro">{infoData.communications.content}</p>
          </div>

          <h2 className="widget-title">{infoData.requiredMaterials.title}</h2>

          <div className="sidebar_author">
            <p className="intro">{infoData.requiredMaterials.content}</p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CourseSelection;
